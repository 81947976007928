<template>
    <div class="container-xxxl px-0">
        <div class="row row-gap-3">
            <Sidebar v-model:visible="visible">
                <div class="card-body">
                    <h3 class="">Filter Options</h3>
                    <div class="d-flex flex-column align-items-start gap-2 mb-2">
                        <InputText v-model="volunteerName" type="text" placeholder="Name" class="w-100" />
                        <Dropdown v-model="selectedProvince" :options="provinves" optionLabel="name"
                            placeholder="Province" class="w-100" />
                        <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" placeholder="City"
                            class="w-100" :disabled="!selectedProvince" />
                        <MultiSelect v-model="selectedSkills" display="chip" :options="skills" optionLabel="name"
                            placeholder="Select Skills" :maxSelectedLabels="100" class="w-100" />
                        <MultiSelect v-model="selectedEducation" display="chip" :options="education" optionLabel="name"
                            placeholder="Select Causes" :maxSelectedLabels="100" class="w-100" />
                    </div>
                    <Button type="button" class="btn btn-primary w-100" label="Search" icon="pi pi-search"
                        @click="searchVolunteers" />
                    <Button type="button" class="btn btn-secondary w-100" label="Reset Filter" icon="pi pi-times"
                        @click="resetFilters" />
                </div>
            </Sidebar>
            <div class="d-flex flex-column align-items-start gap-2">
                <DataView :value="volunteers" :layout="layout">
                    <template #header>
                        <div class="row justify-content-between flex-wrap row-gap-2 p-buttonset">
                            <div
                                class="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center justify-content-sm-start justify-content-center flex-wrap">
                                <h3 class="mb-0 me-3">Explore Volunteers</h3>
                                <Chip class="pl-0 pr-3">
                                    <span class="ml-2 font-medium small">
                                        <b>{{ volunteersCount }}</b>
                                        Found
                                    </span>
                                </Chip>
                            </div>
                            <div
                                class="col-lg-7 col-md-7 col-sm-12 d-flex align-content-center gap-2 justify-content-end flex-wrap">
                                <div
                                    class="d-flex align-items-center justify-content-sm-end justify-content-center flex-grow-1 d-none">
                                    <Button type="button" class="btn btn-primary-sm" label="Mark Completed All" />
                                </div>
                                <div
                                    class="d-flex align-items-center justify-content-center gap-2 flex-grow-1 flex-sm-nowrap flex-wrap">
                                    <div class="p-float-label sortable-select w-100">
                                        <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label"
                                            placeholder="Sort Volunteers" @change="onSortChange($event)"
                                            class="w-100" />
                                    </div>
                                    <DataViewLayoutOptions class="d-flex" v-model="layout" />
                                    <Button class="filter-opt" icon="pi pi-filter-fill" @click="visible = true" />
                                </div>
                            </div>
                        </div>

                    </template>

                    <template #list="slotProps">
                        <div v-for="(event, index) in slotProps.items" :key="index">
                            <div v-if="loading" class="row w-100 mx-0 p-4" style="z-index: 99;">
                                <div class="col-lg-2 px-0">
                                    <Skeleton class="w-8rem border-round h-9rem" />
                                </div>
                                <div class="col-lg-7 px-0 gap-1">
                                    <Skeleton class="w-8rem border-round h-2rem my-1" />
                                    <div class="row mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem" />
                                        <Skeleton class="w-8rem border-round h-2rem" />
                                        <Skeleton class="w-4rem border-round h-2rem" />
                                    </div>
                                    <div class="row flex-column mx-0 gap-2">
                                        <Skeleton class="w-6rem border-round h-2rem mt-1" />
                                        <Skeleton class="w-20rem border-round h-2rem" />
                                    </div>
                                </div>
                                <div class="col-lg-3 px-0">
                                    <Skeleton class="w-100 border-round h-9rem" />
                                </div>
                                <Divider style="margin: 0.5rem 0 !important;"></Divider>
                            </div>
                            <div v-else class="card-body row mx-0 row-gap-3 pb-0">
                                <div class="col-lg-2 col-md-12 col-sm-12 px-0">
                                    <img v-if="!event.profile_picture_thumb" :src="this.$volunteerNoImage(event.gender)"
                                        class="activity-img-block border-radius img-fluid" />
                                    <Image v-else class="border-radius img-fluid"
                                        :src="this.imageurl + '/' + event.profile_picture_thumb" :alt="event.user?.name"
                                        preview />
                                </div>
                                <div
                                    class="col-lg-7 col-md-7 col-sm-12 text-truncate px-3 d-flex flex-column row-gap-2">
                                    <a :href="baseUrl + '/volunteers/' + event.slug">
                                        <h3 class="mb-1 mt-1 mt-sm-0 text-truncate-1">
                                            {{ event.user?.name }}
                                        </h3>
                                    </a>
                                    <p class="mb-2 d-flex flex-wrap fs-7 gap-2 row-gap-2">
                                        <span v-if="event.gender" class="badge-custom d-xl-inline-block">
                                            {{ this.$getFormattedGender(event.gender) }}
                                        </span>
                                        <span v-if="event.education" class="badge-custom d-xl-inline-block">
                                            {{ event.education?.name }}
                                        </span>
                                        <span v-if="event.education_field" class="badge-custom d-xl-inline-block">
                                            {{ event.education_field?.name }}
                                        </span>
                                        <span v-if="event.university" class="badge-custom d-xl-inline-block">
                                            {{ event.university?.name }}
                                        </span>
                                    </p>
                                    <div class="d-flex align-items-center flex-wrap gap-3 row-gap-1 mb-2">
                                        <span v-if="event.city?.name" class="my-1 fs-7">
                                            <strong>City:</strong> {{ event.city?.name }}
                                        </span>
                                        <span v-else class="my-1 fs-7">
                                            <strong>City:</strong> N/A
                                        </span>
                                        <span v-if="event.user && event.user.email" class="my-1 fs-7">
                                            <strong>Email:</strong> {{ event.user.email }}
                                        </span>
                                        <span v-if="event.user && event.user.phone" class="my-1 fs-7">
                                            <strong>Phone:</strong> {{ event.user.phone }}
                                            <a :href="'https://wa.me/92' + event.user.phone " target="_blank">
                                                <i class="fab fa-whatsapp fs-6 ml-1 mr-2 align-self-center text-primary" aria-hidden="true"></i>
                                            </a>
                                        </span>
                                    </div>
                                    <p v-if="event.about" class="text-truncate-2 w-fit" v-tooltip.bottom="event.about">
                                        {{ event.about }}
                                    </p>
                                </div>
                                <div
                                    class="col-lg-3 col-md-12 col-sm-12 card-section-right d-flex align-items-center justify-content-center flex-wrap card">
                                    <div class="row mx-0 flex-wrap w-100 justify-content-center row-gap-2">
                                        <div class="ratinng-box d-flex justify-content-center row-gap-2">
                                            <div v-if="event.ratings.avgRating > 0"
                                                class="d-flex align-items-start justify-content-center gap-2 flex-nowrap">
                                                <div
                                                    class="d-flex gap-1 align-items-center text-primary fs-8 flex-nowrap w-100">
                                                    <span v-for="v in Math.floor(event.ratings.avgRating)" :key="v"
                                                        class="fas fa-star filled"></span>
                                                    <span v-if="event.ratings.halfRating % 1 !== 0"
                                                        class="fas fa-star-half-alt filled"></span>
                                                    <span v-if="event.ratings.avgRating < 5"
                                                        class="d-flex align-items-center gap-1"><span
                                                            v-for="v in Math.ceil(event.ratings.zeroRating)"
                                                            :key="'empty-' + v" class="fa-regular fa-star"></span>
                                                    </span>
                                                </div>
                                                <span class="text-secondary w-100 fs-9">
                                                    ({{ calculateRatingOutOf5(event.ratings.avgRating,
                event.ratings.fullRating) }} out of 5.0)
                                                </span>
                                            </div>
                                            <div v-else
                                                class="d-flex align-items-start justify-content-center gap-2 flex-nowrap">
                                                <div class="d-flex gap-1 align-items-start text-muted opacity-50 fs-8">
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                    <span class="fa-regular fa-star"></span>
                                                </div>
                                                <span class="text-muted opacity-50 fs-9"> (0 out of 5.0)
                                                </span>
                                            </div>
                                        </div>
                                        <div
                                            class="d-flex align-items-center flex-flow-1 gap-2 px-0 flex-wrap row-gap-0">
                                            <a class="btn btn-secondary-sm flex-auto"
                                                @click="viewImages(event.id), ImagesVisible = true"
                                                v-if="event.volunteer_event_images_dashboard_count">
                                                View Images
                                            </a>
                                            <div v-else class="btn btn-secondary-sm flex-auto disabled opacity-25"
                                                v-tooltip.bottom="'Image Not Added'">
                                                View Images
                                            </div>
                                            <a v-if="event.reviews.length > 0" class="btn btn-primary-sm flex-auto"
                                                @click="addReviewAndHours(event.id), RviewVisible = true"> Reviewed
                                            </a>
                                            <a v-else class="btn btn-primary-sm flex-auto"
                                                @click="addReviewAndHours(event.id), RviewVisible = true"> Add Review
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <Divider class="mb-0"></Divider>
                            </div>
                        </div>
                    </template>

                    <template #grid="slotProps">
                        <div class="grid grid-nogutter">
                            <div v-for="(event, index) in slotProps.items" :key="index"
                                class="sm:col-12 lg:col-3 xl:col-3 p-2">
                                <div
                                    class="p-3 border-1 surface-border surface-card border-round position-relative overflow-hidden flex-wrap">
                                    <div v-if="loading" class="row mx-0 row-gap-1" style="z-index: 99;">
                                        <div class="col-lg-4 px-1">
                                            <Skeleton class="w-100 border-round h-100" />
                                        </div>
                                        <div class="col-lg-8 px-1 d-flex flex-column row-gap-1">
                                            <Skeleton class="w-100 border-round h-2rem" />
                                            <Skeleton class="w-100 border-round h-2rem" />
                                        </div>
                                        <div class="col-lg-12 px-1 d-flex flex-column row-gap-1">
                                            <Skeleton class="w-100 border-round h-3rem" />
                                            <Skeleton class="w-100 border-round h-2rem" />
                                        </div>
                                    </div>
                                    <div v-else class="row mx-0 align-items-start flex-wrap row-gap-2">
                                        <div class="col-lg-4 px-0">
                                            <img v-if="!event.profile_picture_thumb"
                                                :src="this.$volunteerNoImage(event.gender)"
                                                class="activity-img-block border-radius img-fluid" />
                                            <Image v-else class="border-radius img-fluid"
                                                :src="this.imageurl + '/' + event.profile_picture_thumb"
                                                :alt="event.user?.name" preview />
                                        </div>
                                        <div class="col-lg-8 text-left px-0 px-sm-0 px-md-0 px-lg-2">
                                            <a :href="baseUrl + '/volunteers/' + event.slug">
                                                <h3 title=""
                                                    class="mb-0 mt-0 text-truncate-2 fw-bold text-capitalize fs-7"
                                                    v-tooltip.top="event.user?.name">
                                                    {{ event.user?.name }}
                                                </h3>
                                            </a>
                                            <div class="col-lg-12 d-flex align-items-top flex-wrap text-left px-0 fs-8">
                                                <p v-if="event.city?.name" class="my-1 fs-8">
                                                    <strong>City:</strong> {{ event.city?.name }}
                                                </p>
                                                <p v-else class="my-1 fs-8">
                                                    <strong>City:</strong> N/A
                                                </p>
                                            </div>
                                        </div>

                                        <div
                                            class="row mx-0 flex-wrap w-100 justify-content-center row-gap-2 card-section-right">
                                            <div class="ratinng-box d-flex justify-content-center flex-auto">
                                                <div v-if="event.ratings.fullRating || event.ratings.halfRating"
                                                    class="d-flex align-items-start text-primary gap-2 flex-wrap">
                                                    <div class="d-flex gap-1 justify-content-center text-primary fs-8">
                                                        <i v-for="(rating, index) in event.ratings.fullRating"
                                                            class="fas fa-star rating" :key="index"></i>
                                                        <i v-if="event.ratings.halfRating"
                                                            class="fas fa-star-half-alt rating"></i>
                                                        <i v-for="(rating, index) in event.ratings.zeroRating"
                                                            class="fa-regular fa-star" :key="index"></i>
                                                    </div>
                                                    <span class="text-secondary fs-9">
                                                        ({{ (event.ratings.fullRating +
                event.ratings.halfRating).toFixed(1) }} out of 5.0)
                                                    </span>
                                                </div>
                                                <div v-else
                                                    class="d-flex align-items-start justify-content-center flex-grow-1 gap-2 flex-wrap">
                                                    <div
                                                        class="d-flex gap-1 align-items-start text-muted opacity-50 fs-8">
                                                        <span class="fa-regular fa-star"></span>
                                                        <span class="fa-regular fa-star"></span>
                                                        <span class="fa-regular fa-star"></span>
                                                        <span class="fa-regular fa-star"></span>
                                                        <span class="fa-regular fa-star"></span>
                                                    </div>
                                                    <span class="text-muted opacity-50 fs-9"> (0 out of 5.0)
                                                    </span>
                                                </div>
                                            </div>
                                            <div
                                                class="d-flex align-items-center flex-flow-1 gap-2 px-0 flex-wrap row-gap-0">
                                                <a class="btn btn-secondary-sm flex-auto"
                                                    @click="viewImages(event.id), ImagesVisible = true"
                                                    v-if="event.volunteer_event_images_dashboard_count">
                                                    View Images
                                                </a>
                                                <div v-else class="btn btn-secondary-sm flex-auto disabled opacity-25"
                                                    v-tooltip.bottom="'Image Not Added'">
                                                    View Images
                                                </div>
                                                <a v-if="event.reviews.length > 0" class="btn btn-primary-sm flex-auto"
                                                    @click="addReviewAndHours(event.id), RviewVisible = true"> Reviewed
                                                </a>
                                                <a v-else class="btn btn-primary-sm flex-auto"
                                                    @click="addReviewAndHours(event.id), RviewVisible = true"> Add
                                                    Hours &
                                                    Review </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #footer v-if="hasMoreVolunteers">
                        <div class="p-4">
                            <div v-if="hasMoreVolunteers" class="text-center">
                                <button @click="loadMoreVolunteers" class="btn btn-primary" :disabled="showSpinner">
                                    View More
                                    <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px"
                                        strokeWidth="8" fill="transparent" animationDuration="2s"
                                        aria-label="Custom ProgressSpinner" />
                                </button>
                            </div>
                        </div>
                    </template>
                    <template #empty v-if="!loading">
                        <div class="text-center">
                            <img src="@/assets/img/no-data-image.png" alt="No Results Found" class="w-50" />
                        </div>
                    </template>
                </DataView>
            </div>
        </div>
    </div>


    <Dialog v-model:visible="ImagesVisible" modal header="Uploaded by Volunteers" :style="{ width: '50vw' }">
        <carousel class="card justify-content-start">
            <slide v-for="(image, index) in eventImagesByVolunteer" :key="index">
                <div class="carousel__item card-main">
                    <div class="card-body event-img p-0 activitydetail-img">
                        <img :src="this.imageurl + '/' + image.image_thumb" alt="Slider Image" class="img-fluid"
                            @click="openGallery(image.imageUrl)">
                    </div>
                    <div class="card-footer d-flex align-items-center gap-2 justify-content-center">
                        <Button v-if="!image.is_approved" class="bg-primary p-3 border-0" icon="pi pi-check text-white"
                            title="Approve Image" @click="updateDeleteImage(image, 'approve')" />
                        <Button v-if="image.is_approved" class="bg-danger p-3 border-0" icon="fa fa-times-circle"
                            title="Unapprove Image" @click="updateDeleteImage(image, 'disapprove')" />
                        <Button class="bg-danger p-3 border-0" icon="pi pi-trash text-white" title="Delete Image"
                            @click="updateDeleteImage(image, 'delete')" />
                    </div>
                </div>
            </slide>
        </carousel>
        <Galleria v-model:visible="displayBasic" :value="galleryImages" :responsiveOptions="responsiveOptions"
            :numVisible="9" containerStyle="max-width: 50%" :circular="true" :fullScreen="true"
            :showItemNavigators="true">
            <template #item="slotProps">
                <div class="card gallery-main-img">
                    <!-- Use correct prop names to display the images -->
                    <img :src="slotProps.item.imageUrl" style="width: 100%; display: block" />
                    <div class="card-footer bg-white rounded-0">
                        <label class="text-truncate">
                            <Button v-if="!slotProps.item.is_approved" class="bg-primary p-3 border-0"
                                icon="pi pi-check text-white" title="Approve Image"
                                @click="updateDeleteImage(slotProps.item, 'approve')" />
                            <Button v-if="slotProps.item.is_approved" class="bg-danger p-3 border-0"
                                icon="fa fa-times-circle" title="Disapprove Image"
                                @click="updateDeleteImage(slotProps.item, 'disapprove')" />
                            <Button class="bg-danger p-3 border-0" icon="pi pi-trash text-white" title="Delete Image"
                                @click="updateDeleteImage(slotProps.item, 'delete')" />
                        </label>
                    </div>
                </div>
            </template>
            <template #thumbnail="slotProps">
                <!-- Use correct prop names to display the thumbnail images -->
                <img class="gallery-thumbnail-img" :src="this.imageurl + '/' + slotProps.item.image_thumb"
                    style="display: block" />
            </template>
        </Galleria>
        <template #footer>
            <div class="d-flex align-items-center gap-2 justify-content-end">
                <Button class="btn-secondary-sm" label="Close" icon="pi pi-times" @click="ImagesVisible = false" text />
            </div>
        </template>
    </Dialog>
    <Dialog v-model:visible="RviewVisible" modal header="Add Review & Hours" :style="{ width: '40vw' }">
        <div class="flex justify-content-center mb-3 custom-p-rating">
            <Rating v-model="ratingValue" />
        </div>
        <Editor v-model="commentText" editorStyle="height: 120px" />
        <div v-if="this.minHours" class="flex-auto mt-2 custom-p-button">
            <label for="minmax-buttons" class="font-bold block mb-2"> Hours <small>({{ this.minHours }} -
                    {{ this.maxHours }})</small> </label>
            <InputNumber v-model="volunteersHours" inputId="minmax-buttons" mode="decimal" showButtons :min="1"
                :max="100" class="w-100" />
        </div>
        <div class="mt-2">
            <label for="" class="font-bold block mb-2">Issue Certificate</label>
            <Calendar v-if="issueCertificate" class="w-100" v-model="issueCertificate" dateFormat="yy/mm/dd" disabled />
            <Calendar v-else class="w-100" v-model="NewissueCertificate" dateFormat="yy/mm/dd"
                placeholder="yyyy/mm/dd" />
        </div>
        <div class="errors">
            <div>
                <span v-if="this.validationErrors.ratingValue" class="text-danger empty-1 text-validation">
                    {{ this.validationErrors.ratingValue }}
                </span>
            </div>
            <div>
                <span v-if="this.validationErrors.commentText" class="text-danger empty-1 text-validation">
                    {{ this.validationErrors.commentText }}
                </span>
            </div>
            <div>
                <span v-if="this.validationErrors.volunteersHours" class="text-danger empty-1 text-validation">
                    {{ this.validationErrors.volunteersHours }}
                </span>
            </div>
            <div>
                <span v-if="this.validationErrors.NewissueCertificate" class="text-danger empty-1 text-validation">
                    {{ this.validationErrors.NewissueCertificate }}
                </span>
            </div>
        </div>
        <template #footer>
            <div class="d-flex align-items-center gap-2 justify-content-end add-modal">
                <Button class="btn-secondary-sm" label="Close" icon="pi pi-times" @click="RviewVisible = false" text />
                <Button class="btn btn-primary w-auto" icon="pi pi-check text-white" @click="submitReview()"
                    :disabled="showSpinner" style="padding: var(--btn-padding) !important;">
                    Submit Review
                    <ProgressSpinner v-if="showSpinner" style="width: 15px; height: 15px" strokeWidth="8"
                        fill="transparent" animationDuration="2s" aria-label="Custom ProgressSpinner" />
                </Button>
                <span class="d-none">{{ this.volID }}</span>
            </div>
        </template>
    </Dialog>
</template>

<script>
import { getEventDashboardVolunteersCompleted, getVolunteersFilters, getVolunteerEventImages, getVolunteerEventReviewHours } from "@/api";
import { saveVolunteerEventReviewHours } from "@/api";
import Image from 'primevue/image';
import Button from 'primevue/button';
import Divider from 'primevue/divider';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';
import Badge from 'primevue/badge';
import Sidebar from 'primevue/sidebar';
import DataView from 'primevue/dataview';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import Galleria from 'primevue/galleria';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Dialog from 'primevue/dialog';
import Chip from 'primevue/chip';
import Skeleton from 'primevue/skeleton';
import Editor from 'primevue/editor';
import InputNumber from 'primevue/inputnumber';
import Rating from 'primevue/rating';
import ProgressSpinner from 'primevue/progressspinner';



export default {
    components: {
        Image,
        Carousel,
        Slide,
        Pagination,
        Navigation,
        Button,
        Divider,
        InputText,
        Calendar,
        Dropdown,
        MultiSelect,
        Badge,
        Sidebar,
        DataView,
        DataViewLayoutOptions,
        Dialog,
        Galleria,
        Chip,
        Skeleton,
        Editor,
        InputNumber,
        Rating,
        ProgressSpinner,
    },
    data() {
        return {
            imageurl: null,
            loading: true,
            pageLoading: true,
            selectedProvince: null,
            selectedCity: null,
            selectedEventTypes: [],
            selectedSkills: [],
            selectedCities: [],
            selectedEducation: [],
            volunteers: [],
            cities: [],
            provinves: [],
            skills: [],
            education: [],
            galleryImages: [],
            volunteersCount: 0,
            displayBasic: false,
            pageNo: 1,
            perPage: 12,
            sortKey: '',
            sortvalue: null,
            sortType: null,
            imageurl: null,
            volunteerName: null,
            visible: false,
            ImagesVisible: false,
            RviewVisible: false,
            commentText: null,
            ratingValue: null,
            volunteersHours: 0,
            eventImagesByVolunteer: [],
            volID: 0,
            layout: 'list',
            sortOptions: [
                { label: 'Name ASC', value: 'name', type: 'asc' },
                { label: 'Name DESC', value: 'name', type: 'desc' },
                { label: 'Hours Completed DESC', value: 'totalHours', type: 'desc' },
                { label: 'Hours Completed ASC', value: 'totalHours', type: 'asc' },
            ],
            layoutOptions: [
                { name: 'List', icon: 'pi pi-bars' },
                { name: 'Grid', icon: 'pi pi-th-large' }
            ],
            home: {
                icon: 'pi pi-home',
                route: '/'
            },
            items: [
                { label: 'Volunteer dashboard' },
                { label: 'All volunteers' },
            ],
            minHours: 0,
            maxHours: 0,
            validationErrors: [],
            showSpinner: false,
            issueCertificate: null,
            NewissueCertificate: null,
            eventStartDate: null,
            oldCertificate: null,
        }

    },
    computed: {
        hasMoreVolunteers() {
            return this.volunteersCount > this.perPage * this.pageNo;
        },
    },
    props: ['slug'],
    async mounted() {
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        try {
            this.loading = true;
            this.pageLoading = true;
            const params = {
                slug: this.slug,
                pageno: this.pageNo,
                perpage: this.perPage
            };
            const responce = await getEventDashboardVolunteersCompleted(params);
            this.volunteers = responce.volunteers.data;
            this.eventStartDate = responce.startDate;
            this.volunteersCount = responce.volunteersCount;
            const res2 = await getVolunteersFilters();
            this.causes = res2.causes;
            this.allCities = res2.cities;
            this.eventTypes = res2.eventTypes;
            this.provinves = res2.provinves;
            this.skills = res2.skills;
            this.education = res2.Education;
            this.loading = false;
            this.pageLoading = false;
        } catch (error) {
            console.error(error);
        }
    },
    watch: {
        selectedProvince(newProvince) {
            // Filter cities based on the selected province and update the cities options
            if (newProvince) {
                this.cities = this.allCities.filter((city) => city.province_id === newProvince.id);
            } else {
                // If no province is selected, reset the cities options to empty
                this.cities = [];
            }
        },
    },
    methods: {
        async searchVolunteers() {
            try {
                this.loading = true;
                const params = {
                    slug: this.slug,
                    pageno: 1, // Reset the page number to 1 for a new search
                    perpage: this.perPage,
                    name: this.volunteerName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    education_id: this.selectedEducation ? this.selectedEducation.id : null,
                };
                const res = await getEventDashboardVolunteersCompleted(params);
                this.volunteers = res.volunteers.data;
                this.volunteersCount = res.volunteersCount;
                this.loading = false;
            } catch (error) {
                console.error(error);
            }
        },
        async loadMoreVolunteers() {
            this.showSpinner = true;
            try {
                this.loading = true;
                const params = {
                    slug: this.slug,
                    pageno: this.pageNo + 1,  // Reset the page number to 1 for a new search
                    perpage: this.perPage,
                    name: this.volunteerName,
                    province_id: this.selectedProvince ? this.selectedProvince.id : null,
                    city_id: this.selectedCity ? this.selectedCity.id : null,
                    skill_ids: this.selectedSkills.map((skill) => skill.id),
                    education_id: this.selectedEducation ? this.selectedEducation.id : null,
                    sortBy: this.sortvalue,
                    sortType: this.sortType,
                };

                const res = await getEventDashboardVolunteersCompleted(params);
                const newVolunteers = res.volunteers.data;
                this.volunteers = [...this.volunteers, ...newVolunteers];
                this.pageNo++; // Update the current page number
                this.loading = false;
                this.showSpinner = false;
            } catch (error) {
                console.error(error);
            }
        },
        resetFilters() {
            this.loading = true;
            this.volunteerName = '';
            this.selectedProvince = null;
            this.selectedCity = null;
            this.selectedSkills = [];
            this.selectedEducation = [];
            this.searchVolunteers();
            this.loading = false;
        },
        async onSortChange(event) {
            this.loading = true;
            this.sortvalue = event.value.value;
            this.sortType = event.value.type;
            const params = {
                slug: this.slug,
                pageno: this.pageNo,
                perpage: this.perPage,
                sortBy: this.sortvalue,
                sortType: this.sortType,
            };

            const res = await getEventDashboardVolunteersCompleted(params);
            this.volunteers = res.volunteers.data;
            this.volunteersCount = res.volunteersCount;
            this.loading = false;
        },
        async viewImages(volunteerid) {
            const params = {
                slug: this.slug,
                volunteer_id: volunteerid,
            };
            const res = await getVolunteerEventImages(params);
            this.eventImagesByVolunteer = res.data;
        },

        updateDeleteImage(image, status) {
            const result = this.$imageStatus(image, status);
            if (result) {
                this.viewImages(image.volunteer_id);
                this.$imageStatusUpdateToast(status);
            }
        },
        openGallery(imageSrc) {
            // When an image in the carousel is clicked, open the gallery and set the images
            this.galleryImages = [...this.eventImagesByVolunteer];
            const index = this.galleryImages.findIndex((image) => image.imageUrl === imageSrc);
            if (index !== -1) {
                const clickedImage = this.galleryImages.splice(index, 1)[0];
                this.galleryImages.unshift(clickedImage);
            }
            this.displayBasic = true;
        },
        async addReviewAndHours(id) {
            this.commentText = null;
            this.ratingValue = null;
            this.volunteersHours = null;
            this.issueCertificate = null;
            this.volID = id;
            const params = {
                slug: this.slug,
                volunteer_id: id,
            };
            const res = await getVolunteerEventReviewHours(params);
            if (res.data.review) {
                this.commentText = res.data.review.comment;
                this.ratingValue = res.data.review.rating;
                this.issueCertificate = res.data.review ? res.data.review.issue_certificate : null;
            }
            if (res.data.event) {
                this.minHours = res.data.event.min_hours;
                this.maxHours = res.data.event.max_hours;
            }
            if (res.data.hours && res.data.hours) {
                this.volunteersHours = res.data.hours;
            }
        },
        validateData() {
            this.validationErrors = {};
            if (!this.ratingValue) {
                this.validationErrors.ratingValue = 'Rating is required';
            }
            if (!this.commentText) {
                this.validationErrors.commentText = 'Comment is required';
            }
            if (!this.volunteersHours || this.volunteersHours === null) {
                this.validationErrors.volunteersHours = 'Hours is required';
            } else if (this.volunteersHours > this.maxHours) {
                this.validationErrors.volunteersHours = 'Hours cannot be more than max hours!';
            } else if (this.volunteersHours < this.minHours) {
                this.validationErrors.volunteersHours = 'Hours cannot be less than min hours!';
            }

            if (this.issueCertificate && !this.NewissueCertificate === null || this.issueCertificate === null && this.NewissueCertificate === null) {
                this.validationErrors.NewissueCertificate = 'Issue certificate is required';
            }
            if (this.NewissueCertificate) {
                const formattedNewIssueCertificate = this.$formatDate(new Date(this.NewissueCertificate));
                const formattedEventStartDate = this.$formatDate(new Date(this.eventStartDate));

                if (formattedNewIssueCertificate && formattedNewIssueCertificate < formattedEventStartDate) {
                    this.validationErrors.NewissueCertificate = 'Issue certificate date should be greater than event start date';
                }
            }

            // if (this.NewissueCertificate && this.$formatDate(this.NewissueCertificate) < this.eventStartDate) {
            //     this.validationErrors.NewissueCertificate = 'Issue certificate date should be grater than event start date';
            // }
            return Object.keys(this.validationErrors).length === 0;
        },
        async submitReview() {

            if (!this.validateData()) {
                return;
            }
            this.showSpinner = true;
            if (this.issueCertificate) {
                this.oldCertificate = this.issueCertificate;
            }
            if (this.NewissueCertificate) {
                this.NewissueCertificate = this.$formatDate(this.NewissueCertificate);
            }
            try {
                const params = {
                    volunteer_id: this.volID,
                    slug: this.slug,
                    hours: this.volunteersHours,
                    comment: this.commentText,
                    rating: this.ratingValue,
                    is_request: 0,
                    issueCertificate: this.NewissueCertificate ? this.NewissueCertificate : this.oldCertificate,

                };
                const responce = await saveVolunteerEventReviewHours(params);
                if (responce.status == 200) {
                    this.RviewVisible = false;
                    this.$swal.fire({
                        title: 'Success!',
                        text: "Volunteer Review has been submitted successfully.",
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: "#46B849",
                        // allowOutsideClick: false,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.searchVolunteers();
                        }
                    });
                    this.showSpinner = false;
                }
                else {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                    })
                }
            }
            catch (error) {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                })
            }
        },
        calculateRatingOutOf5(avgRating, fullRating) {
            return Math.round(avgRating * 10) / 10;
        },
    },
}
</script>