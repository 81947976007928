import axios from "axios";
import router  from "./router";
import { mapMutations } from 'vuex';


axios.defaults.headers.common["Secret"] = "G8QQgFtkHyXOImq1kpKE4Mpl0ImJhMLYiRVtbk9iwBezQ07ka74f4jgyuqKONhjCwCYpSg7kruqCxWLXKq6Kbr5lRWiKXXeEZ8z7SXxjlVh3WMAWRijS93kObgMvp6ou";
if (localStorage.getItem('token') && !axios.defaults.headers.common['Authorization'])
{
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
}

export const  ForgotPassword = async (email) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + '/api/forgot-password', { email: email });
  return res;
};
export const  resetPassword = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/reset-password", { params } );
  return res;
};
export const  verifyUser = async (verifyToken) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/verify-user", { verifyToken } );
  return res;
};

export const  saveFcmToken = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/store-fcm-token", { params} );
  return res;
};
export const  saveUserLocation = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-user-location", { params} );
  return res;
};
export const getHomeData = () => {
  return axios.get(process.env.VUE_APP_BASE_URL + "/api/home")
};
export const  getlocations = async () => {
    const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/locations")
  return res.data.allLocations
};
export const  getheatMap = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/heatMap");
return res.data.heatMapinfos
};
export const  getallheatMap = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/allHeatMap");
  return res.data
};
export const  getallCauses = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/allCauses");
return res.data.allevents
};

export const  getSliderImages = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/homeSliderImages");
return res.data.homeslider
};
export const  getProvinceActivitiesCount = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/provinceActivitiesCount");
return res.data.provinceActivitiesCount
};
export const  getPopularEvents = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/popularEvents");
return res.data.popularEvents
};
export const  getPresses = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/presses");
return res.data.presses;
};
export const  getAmbassadors = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/ambassadors");
return res.data.ambassadors
};
export const  getOverviewCounts = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/overviewCounts");
return res.data
};
export const getMyCausesCount = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/my-causes-count");
  return res.data;
};
export const getActivitiesCount = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/all-activities-happening-count");
  return res.data;
};
export const getRegisterActivitiesCount = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-registered-count");
  return res.data;
};
export const getInvitationActivitiesCount = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-invitations-count");
  return res.data;
};
export const getRecommendedActivityCount = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-recomended-count");
  return res.data;
};
export const getCompletedActivityCount = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-completed-count");
  return res.data;
};
export const getPendingActivityCount = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-pending-count");
  return res.data;
};
export const  getOrganizations = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/homeOrganizations");
return res.data.organizations;
};
export const  getTestimonial = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteersTestimonial");
return res.data.volunteers;
};
export const  getTeam = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/team");
return res.data.team;
};
export const  getOurAmbassadors = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/ourambassadors" , { params });
return res.data;
};
export const  getOurProjects = async (params) => {
    const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/our-projects" , { params });
  return res.data;
};
export const  getMilkarChampions = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/milkar-champions");
return res.data.champion_events;
};
export const  getPress = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/press");
return res.data.press;
};
export const  getBlogsPosts = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/blogsPost");
return res.data
};
export const  getBlogsPostsByCategory = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/blogsPost" , { params: { slug }});
return res.data
};
export const  getBlogDetail = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/blogDetail" , { params: { slug } });
  return res;
};
export const  getActivities = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities" , { params });
return res.data;
};
export const  getActivitiesFilters = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activitiesFilters");
return res.data;
};
export const  getActivity = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities/activity" , { params: { slug } });
return res.data;
};
export const  getActivityV2 = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities/activityV2" , { params: { slug } });
return res.data;
};
export const  getHomeActivity = async (id) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/getActivity" , { params: { id } });
return res.data;
};
export const  getActivitRregisteredVolunteers = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities/activity/registered-volunteers" , { params: { slug } });
return res.data;
};
export const  getActivityImages = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities/activity/images" , { params: { slug } });
return res.data;
};
export const  getActivityReviews = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities/activity/reviews" , { params: { slug } });
return res.data.activityReviews;
};
export const  getActivityVolunteerReview = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activity-review" , { params: { slug } });
return res.data.activityReviews;
};
export const  submitActivityVolunteerReview = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/submit-activity-review" , { params } );
return res;
};
export const  getVolunteers = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteers" , { params });
return res.data;
};
export const  getVolunteerReviews = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteers/volunteer/reviews" , { params });
return res.data.volunteerReviews;
};
export const  getVolunteerRegistered = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteers/volunteer/activities-registered" , { params });
return res.data.volunteerEvents;
};
export const  getVolunteerImages = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteers/volunteer/volunteer-images" , { params });
return res.data.volunteerImages;
};
export const  getVolunteersFilters = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteersFilters" , { params });
return res.data;
};
export const  getVolunteer = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteers/volunteer" , { params: { slug } });
return res.data.volunteer;
};
export const  getVolunteerV2 = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteers/volunteer_v2" , { params: { slug } });
return res.data.volunteer;
};
export const  getOrganizationsList = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/organizations", { params });
return res.data;
};
export const  getOrganization = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/organizations/organization" , { params: { slug } });
  return res.data;
};
export const  getOrganizationV2 = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/organizations/organization_v2" , { params: { slug } });
  return res.data;
};
export const  getOrganizationEvents = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/organizations/organization/activities" , { params });
return res.data.organizationEvents;
};
export const  getCities = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/cities");
return res.data.cities;
};
export const  getInstitutes = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/institutes" , { params });
  return res.data;
};
export const  getSchools = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/partnerSchools" , { params });
  return res.data;
};
export const  getColleges = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/partnerColleges" , { params });
  return res.data;
};
export const  getUniversities = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/partnerUniversities" , { params });
  return res.data;
};
export const  getUniversitiesList = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/universities" );
  return res.data.universities;
};
export const  getMyCauses = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/my-causes", { params } );
  return res;
};
export const  getActivitiesInvitations = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-invitations", { params } );
  return res;
};
export const  getActivitiesHappeningNow = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/all-activities-happening", { params } );
  return res.data;
};
export const  getActivitiesRegistered = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-registered", { params } );
  return res;
};
export const  getActivitiesRecomended = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-recomended", { params } );
  return res;
};
export const  getActivitiesÇompleted = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-completed", { params } );
  return res;
};
export const  getActivitiesPending = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/activities-pending", { params } );
  return res;
};
export const  getVolunteerDetail = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteer-detail", { params } );
  return res.data;
};
export const  getOrganizationDetail = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/organization-detail", { params } );
  return res.data;
};
export const  getOrganizationActivites = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/organization-events", { params } );
  return res.data;
};
export const  postUpdateEventStatus = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-event-status", { params } );
  return res;
};

export const  getVolunteerEventStatus = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/check-voluteer-event-status", { params: { slug }} );
  return res;
};
export const  createVolunteerEvent = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/create-volunteer-cause", { params } );
  return res;
};
export const  updateVolunteerEvent = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-volunteer-cause", { params } );
  return res;
};
export const  getUserDeatails = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/organization-details");
  return res;
};
export const  getVolunteerUserDeatails = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteer-details");
  return res;
};
export const  updateVolunteerProfile = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/save-volunteer-info", { params } );
  return res;
};
export const  updateOrganizationProfile = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-organization", { params } );
  return res;
};
export const  isOwnerFlag = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/is-owner", { params: { slug } });
  return res;
};
export const  getEventDashboardVolunteersRequests = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/requested-volunteers" , { params });
  return res.data;
};
export const  getEventDashboardVolunteersRecommended = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/recommended-volunteers" , { params });
  return res.data;
};
export const  getEventDashboardVolunteersShortlisted = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/shortListed-volunteers" , { params });
  return res.data;
};
export const  getEventDashboardVolunteersCompleted = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/completed-volunteers" , { params });
  return res.data;
};
export const  getEventDashboardVolunteersPendings = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/pending-volunteers" , { params });
  return res.data;
};
export const  updateVolunteerEventByOwner = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-event-status-by-volunteer", { params } );
  return res;
};
export const  updateVolunteerByAll = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-event-status-by-owner-all", { params } );
  return res;
};
export const  updateVolunteerEventByVolunteer = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-event-status-by-volunteer-register", { params } );
  return res;
};
export const  getVolunteerActivityStatus = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteer-activity-status", { params } );
  return res;
};
export const  updateEventImageOrDelete = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-event-image-delete", { params } );
  return res;
};
export const  getVolunteerEventImages = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/get-volunteer-event-image", { params } );
  return res;
};
export const  getVolunteerEventReviewHours = async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/get-volunteer-event-review-hours", { params } );
  return res;
};
export const  saveVolunteerEventReviewHours = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/save-volunteer-event-review-hours", { params } );
  return res;
};
export const  updateEventImage = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-event-image", { params } );
  return res;
};
export const  createActivity = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/create-activity", { params } );
  return res;
};
export const  registerEducationalOrg = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/register-educational-institute", { params } );
  return res;
};

export const  updateActivity = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-activity", { params } );
  return res;
};
export const  updateProfileImage = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/update-profile-image", { params } );
  return res;
};
export const  requestForReview = async (slug) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/volunteer-request-review", { slug } );
  return res;
};
export const  uploadVolunteerProofImages = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/upload-event-proof-images", { params } );
  return res;
};
export const  getMyEventProofImages = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/my-event-proof-images", { params: { slug } } );
  return res;
};
export const  getMyEventProofImagesDescription = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/get-volunteer-image-proof", { params: { slug } } );
  return res.data;
};
export const  downloadVolunteerCv = async () => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteer-cv",{responseType: 'arraybuffer'});
  return res.data;
};
export const  downloadVolunteerCirtificate = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteer-cirtificate",{params: { slug },responseType: 'arraybuffer'});
  return res.data;
};
export const  saveVolunteerImagesProof = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/save-volunteer-image-proof", { params} );
  return res;
};
export const  uploadOwnerImages= async (params) => {
  return await axios.get(process.env.VUE_APP_BASE_URL + "/api/upload-owner-images" , { params });
};
export const  getEventDashboardVolunteersRequestsCount = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/requested-volunteers-count" , { params: { slug } });
  return res.data;
};
export const  getEventDashboardVolunteersRecommendedCount = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/recommended-volunteers-count" , { params: { slug } });
  return res.data;
};
export const  getEventDashboardVolunteersShortlistedCount = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/shortListed-volunteers-count" , { params: { slug } });
  return res.data;
};
export const  getEventDashboardVolunteersCompletedCount = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/completed-volunteers-count" , { params: { slug } });
  return res.data;
};
export const  getEventDashboardVolunteersPendingsCount = async (slug) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/pending-volunteers-count" , { params: { slug } });
  return res.data;
};
export const  socialLogins= async (provider) => {
  return await axios.get(process.env.VUE_APP_BASE_URL + "/api/social-login" , { params: { provider } });
};
export const  socialLoginsCallback= async (provider) => {
  return await axios.get(process.env.VUE_APP_BASE_URL + "/api/social-login" + { provider } + "/callback" );
};
export const  loadNotifications= async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/load-notifications",{ params } );
  return res.data;
};
export const  readNotifications= async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/read-notifications",{ params } );
  return res.data;
};
export const  readLoadRequests= async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/read-load-requests",{ params } );
  return res.data;
};
export const  loadRequests= async (params) => {
  const res = await axios.get(process.env.VUE_APP_BASE_URL + "/api/load-requests",{ params });
  return res.data;
};
export const  getSmsCampaignList= async () => {
  return await axios.get(process.env.VUE_APP_BASE_URL + "/api/sms-campaign");
};
export const  postSmsCampaign = async (compaignName, smsTxt , file) => {
  const headers = {
    'Content-Type': 'multipart/form-data',
  };
  const formData = new FormData();
  formData.append('compaign_name', compaignName);
  formData.append('sms_txt', smsTxt);
  formData.append('volunteer_list', file);

  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/post-create-sms-compaign", formData, { headers} );
  return res;
};
export const  postSmsCampaignByCities= async (params) => {
  return await axios.post(process.env.VUE_APP_BASE_URL + "/api/post-create-sms-compaign-milkar",{ params });
};
export const  getCountVolunteerNumbers= async (params) => {
  return await axios.get(process.env.VUE_APP_BASE_URL + "/api/count-volunteer-numbers",{ params });
};
export const  deleteSmsCompain = async (id) => {
  const res = await axios.delete(process.env.VUE_APP_BASE_URL + "/api/delete-sms-campaign/" +  id);
  return res;
};
export const  contactUs = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/contact", { params } );
  return res;
};
export const  uploadSingleImageBackend = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/upload-image",  params , {
    headers: {
      'Content-Type': 'multipart/form-data',
  },
  });
  return res;
};
export const  uploadMultipleImageBackend = async (params) => {
  const res = await axios.post(process.env.VUE_APP_BASE_URL + "/api/upload-multiple-images",  params , {
    headers: {
      'Content-Type': 'multipart/form-data',
  },
  });
  return res;
};
export const  deleteImageBackend = async (params) => {
  const res = await axios.delete(process.env.VUE_APP_BASE_URL + "/api/delete-image",  {params});
  return res;
};

export async function volunteerDataApi() {
  try {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + "/api/volunteer-dashboard");
    if (response) {
      if (!localStorage.getItem("volunteer"))
      {
        localStorage.setItem("volunteer",  JSON.stringify(response.data.volunteer));
      }
      return response;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function organizationDataApi() {
  try {
    const response = await axios.get(process.env.VUE_APP_BASE_URL + "/api/organization-dashboard");
    if (response) {
      if (!localStorage.getItem("organization"))
      {
        localStorage.setItem("organization",  JSON.stringify(response.data.organization));
      }
      return response;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}
