import { postUpdateEventStatus, updateVolunteerByAll, uploadSingleImageBackend, deleteImageBackend } from "@/api";
import { getVolunteerEventStatus } from "@/api";
import { updateVolunteerEventByOwner } from "@/api";
import { updateEventImageOrDelete } from "@/api";
import { updateEventImage } from "@/api";
import { updateProfileImage } from "@/api";
import { updateVolunteerEventByVolunteer } from "@/api";
import { getVolunteerActivityStatus } from "@/api";
import AWS from 'aws-sdk';
import ToastService from 'primevue/toastservice';
import Compressor from 'compressorjs';

export const globalMethods = {
    components: {
        Compressor,
    },
    stripHTML(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || '';
    },
    isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    },
    getAge(dob) {
        if (dob) {
            const dobParts = this.volunteer.dob.split('-');
            const dobYear = parseInt(dobParts[0]);
            const dobMonth = parseInt(dobParts[1]) - 1; 
            const dobDay = parseInt(dobParts[2]);
            const dobDate = new Date(dobYear, dobMonth, dobDay);
            const currentDate = new Date();
            let age = currentDate.getFullYear() - dobDate.getFullYear();

            if (
            currentDate.getMonth() < dobDate.getMonth() ||
            (currentDate.getMonth() === dobDate.getMonth() &&
            currentDate.getDate() < dobDate.getDate())
            ) {
            age--; 
            }
            // Return the calculated age
            return age;
        } else {
            // console.log('DOB is not available');
            return null; // Return null or another suitable value when DOB is not available
        }
    },
    volunteerNoImage(gender){
        if (gender == 'm') {
            return require('@/assets/img/male_placeholder_2.png');
        }
        else if (gender == 'f')
        {
            return require('@/assets/img/female_placeholder_2.png');
        } 
        else {
            return require('@/assets/img/3x_placeholder.png');
        }
    },
    volunteerImageUrl(image){
        this.baseUrl = window.location.origin;
        this.imageurl = process.env.VUE_APP_AWS_ACCESS_URL;
        const url = this.imageurl + '/' + image;
        return url;
    },
    activityNoImage(){
        return require('@/assets/img/org_placeholder.png');
    },
    checkEndDate(date) {
        const currentDate = new Date();
        const date1 = new Date(date);
        if (currentDate >= date1) {
            return 0;
        }
        else {
            return 1;
          }
    },
    checkStartDate(start_date){
        const today = new Date();
        const formatedToday = this.$formatDate(today);
        const eventDate = start_date;
        if(formatedToday < eventDate){
            return 0;
        } else {
            return 1;
        }
    },
     async statusClicked(id, status){
        try{
            this.isButtonLoading = true;
            const params = {
                event_id: [id],
                status: status
            };
            const res = await postUpdateEventStatus(params);
            return res;
        }
        catch (error) {
            console.error(error);
            this.isButtonLoading = false;
        }
    },
    async checkActivityStatus(volunteerId, eventId){
        var status = 0;
        try{
            const params = {
                volunteer_id: volunteerId,
                event_id: eventId
            };
            const res = await getVolunteerEventStatus(params);
            status = parseInt(res.data.status);
        }
        catch (error) {
            console.error(error);
        }
        return status;
    },
    formattedTime(startTime) {
        const [hours, minutes] = startTime.split(":");
        const time = new Date();
        time.setHours(hours);
        time.setMinutes(minutes);
        const ampm = time.getHours() >= 12 ? "PM" : "AM";
        let hours12 = time.getHours() % 12;
        hours12 = hours12 ? hours12 : 12; // convert 0 to 12
        return `${hours12}:${minutes} ${ampm}`;
      },
      getFormattedGender(gender) {
        return gender === 'f' ? 'Female' : gender === 'm' ? 'Male' : 'unknown';
    },
    async updateEventStatus(volunteerId, slug, status){
        try{
            const params = {
                volunteer_id: volunteerId,
                slug: slug,
                status: status
            };
            const res = await updateVolunteerEventByOwner(params);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    },
    async updateVolunteerAll(slug, status){
        try{
            const params = {
                slug: slug,
                status: status
            };
            const res = await updateVolunteerByAll(params);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    },
    async updateEventStatusVolunteer(volunteerId, slug, status){
        try{
            const params = {
                volunteer_id: volunteerId,
                slug: slug,
                status: status
            };
            const res = await updateVolunteerEventByVolunteer(params);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    },
    async getVolActivityStatus(volunteerId, slug) {
        try{
            const params = {
                volunteer_id: volunteerId,
                slug: slug,
            };
            const res = await getVolunteerActivityStatus(params);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    },
    async imageStatus(image, status){
        try{
            const params = {
                id: image.id,
                status: status
            };
            const res = await updateEventImageOrDelete(params);
            if (status == 'delete' )
            {
                this.$deleteImageFromS3(image.image , image.image_thumb)
            }
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    },
    async compressImage(file) {
        // console.log(file);
        return new Promise((resolve, reject) => {
            const compressor = new Compressor(file, {
                quality: 0.6,
                maxWidth: 400,
                maxHeight: 400,
                success(result) {
                    resolve(result);
                },
                error(err) {
                    reject(err);
                },
            });

        });
    },
    async deleteImageFromS3(imageKey, thumbImageKey) {   
        try {
            const params = {
                imageKey: imageKey,
                thumbImageKey: thumbImageKey
            };
    
            // Call the backend API to delete the images
            const response = await deleteImageBackend(params);
            
            if (response.status === 200) {
                console.log(`Image '${imageKey}' and thumbnail '${thumbImageKey}' deleted successfully.`);
            } else {
                console.error(`Failed to delete images. Server responded with status ${response.status}`);
            }
        } catch (error) {
            console.error(`Error deleting images from backend:`, error);
        }
    },
    imageStatusUpdateToast(status){
        var msg = "";
        var type = "";
        if(status == 'approve')
        {
            msg = "This image has been approved.";
            type = "success";
        }
        if (status == "disapprove")
        {
            msg = "This image has been disapproved.";
            type = "error";
        }
        if (status == "delete")
        {
            msg = "This image has been deleted.";
            type = "success";
        }
        this.$toast.open({
            message: msg,
            type: type,
            position: "top-right",
            duration: 5000,
            dismissible: true,
        })
    },
    getFileFormat(selectedFile, filenamed, type) {
        return new Promise((resolve, reject) => {
            const byteString = atob(selectedFile.split(',')[1]);
            const mimeString = selectedFile.split(',')[0].split(':')[1].split(';')[0];
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
    
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
    
            const blob = new Blob([ab], { type: mimeString });
            var fileName = "";
            // You can customize the file name if needed
            if (filenamed){
                fileName = filenamed;
            }
            else {
                fileName = "image.png";
            }
            const file = new File([blob], fileName, { type: type });
    
            resolve(file);
        });
    },
    async uploadSingleImage(file, original) {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('original', original);
    
            const response = await uploadSingleImageBackend(formData);
            // console.log(response);
            return [response.data.original_url, response.data.thumb_url];
        } catch (error) {
            console.error('Error uploading images:', error);
            this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to upload images', life: 3000 });
        }
    },
    async updateEventImage(params) {
        try {
            const res = await updateEventImage(params);
            this.$swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Image has been uploaded',
                confirmButtonText: 'OK'
            })
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                this.validationErrors= error.response.data.errors;
            } else {
                this.errored = true;
            }
            // console.error(error);
            // this.errored = true;
        }
    },
    async updateUserProfileImage(params) {
        try {
            const res = await updateProfileImage(params);
            console.log(res.data);
            this.$swal.fire({
                position: 'center',
                icon: 'success',
                title: 'Image has been uploaded',
                confirmButtonText: 'OK'
            })
        }
        catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                this.validationErrors= error.response.data.errors;
            } else {
                this.errored = true;
            }
            // console.error(error);
            // this.errored = true;
        }
    },
    getOnlyTimeFromDate(selecteddate) {
        const date = new Date(selecteddate);
        // Use Date methods to extract hours, minutes, and seconds
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        // Format time as HH:MM:SS
        let time = this.$formatTime(hours) + ':'+this.$formatTime(minutes) + ':' +this.$formatTime(seconds);
        return time;
    },
    formatDate(date) {
        // Format the date as DD-MM-YYYY
        if (!(date instanceof Date && !isNaN(date))) {
            return ''; // Return an empty string or handle the error as needed
        }    
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    },
    formatTime(time) {
        // Add leading zero if time is less than 10
        return time < 10 ? `0${time}` : time;
    },
    convertTo24HourFormat(time) {
        const twelveHourPattern = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
        const twentyFourHourPattern = /^([01][0-9]|2[0-3]):[0-5][0-9]$/;
  
        if (twelveHourPattern.test(time)) {
          // Convert 12-hour format (e.g., 2:30 PM) to 24-hour format
          const [timePart, period] = time.split(' ');
          const [hours, minutes] = timePart.split(':');
          let convertedHours = parseInt(hours, 10);
          if (period.toLowerCase() === 'pm' && convertedHours < 12) {
            convertedHours += 12;
          } else if (period.toLowerCase() === 'am' && convertedHours === 12) {
            convertedHours = 0;
          }
          return `${String(convertedHours).padStart(2, '0')}:${minutes}:00`;
        } else if (twentyFourHourPattern.test(time)) {
          // Time is already in 24-hour format (e.g., 14:30)
          return `${time}:00`;
        } else {
          return 'Invalid Time';
        }
    },
    // getExpiry(endDate) {
    //     const date = new Date();
    //     const endDate2 = endDate;
    //     if(endDate2 >= this.$formatDate(date)) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // }
};