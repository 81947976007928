<template>
    <div class="card multipleimagesupload">
        <!-- <Toast /> -->
        <Toast ref="toast" position="top-right"></Toast>
        <FileUpload name="demo[]" :fileLimit="3" @upload="onTemplatedUpload($event)" :multiple="true" accept="image/*"
            :maxFileSize="6000000" @select="onSelectedFiles" >
            <template #header="{ chooseCallback, uploadCallback, clearCallback, files }">
                <div class="flex flex-wrap justify-content-between align-items-center flex-1 gap-2">
                    <div class="flex gap-2">
                        <Button @click="chooseCallback()" icon="pi pi-images" rounded outlined></Button>
                        <Button @click="uploadEvent(uploadCallback)" icon="pi pi-cloud-upload" rounded outlined
                            severity="success" :disabled="loading || !files || files.length === 0 || files.length > 5" v-tooltip.bottom="'Upload images'"></Button>
                        <Button @click="clearCallback()" icon="pi pi-times" rounded outlined
                            severity="danger" :disabled="!files || files.length === 0"></Button>
                    </div>
                    <ProgressBar :value="totalSizePercent" :showValue="false"
                        :class="['md:w-20rem h-1rem w-full md:ml-auto', { 'exceeded-progress-bar': totalSizePercent > 100 }]">
                        <span class="white-space-nowrap">{{ totalSize }}B / 1Mb</span>
                    </ProgressBar>
                </div>
            </template>
            <template #content="{ files, uploadedFiles, removeUploadedFileCallback, removeFileCallback }">
                <div v-if="files.length > 0">
                    <h5>Pending</h5>
                    <div class="row mx-0 my-3 row-gap-2 px-0">
                        <div class="col-xl-4 col-md-4 col-sm-12 upload-img-close" v-for="(file, index) of files"
                            :key="file.name + file.type + file.size">
                            <Button icon="pi pi-times" @click="onRemoveTemplatingFile(file, removeFileCallback, index)"
                                outlined rounded severity="danger" />
                            <div class="create-cause-upload-box bg-white rounded shadow-sm p-3 text-center">
                                <Image role="presentation" :style="{ backgroundImage: 'url(' + file.objectURL + ')' }"
                                    class="" />

                                <h5 class="mb-0 text-capitalize text-truncate-1 mt-4" :title="file.name">
                                    {{ file.name }}
                                </h5>
                                <div
                                    class="d-flex align-items-center justify-content-center gap-1 text-capitalize text-muted fs-7 mb-1">
                                    <span>
                                        {{ formatSize(file.size) }}
                                    </span>
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-center row-gap-2">
                                    <Badge value="Pending" severity="warning"  v-tooltip.bottom="'Click on upload icon'"/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="uploadedFiles.length > 0">
                    <h5>Completed</h5>
                    <div class="row mx-0 my-3 row-gap-2 px-0">
                        <div class="col-xl-4 col-md-4 col-sm-12 upload-img-close" v-for="(file, index) of uploadedFiles"
                            :key="file.name + file.type + file.size">
                            <Button icon="pi pi-times" @click="removeUploadedFileCallback(index), deleteImageFromS3(file)"
                                outlined rounded severity="danger" />
                            <div class="create-cause-upload-box bg-white rounded shadow-sm p-3 text-center">
                                <Image role="presentation" :style="{ backgroundImage: 'url(' + file.objectURL + ')' }"
                                    class="" />
                                <h5 class="mb-0 text-capitalize text-truncate-1 mt-4" :title="file.name">
                                    {{ file.name }}
                                </h5>
                                <div
                                    class="d-flex align-items-center justify-content-center gap-1 text-capitalize text-muted fs-7">
                                    <span>
                                        {{ formatSize(file.size) }}
                                    </span>
                                </div>
                                <div class="d-flex flex-column justify-content-center align-items-center row-gap-2">
                                    <Badge value="Completed" class="mt-3" severity="success" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>
                <div class="flex align-items-center justify-content-center flex-column">
                    <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl text-400 border-400" />
                    <p class="mt-4 mb-0">Drag and drop files to here to upload.</p>
                </div>
            </template>
        </FileUpload>
    </div>
</template>

<script>
import FileUpload from 'primevue/fileupload';
import ProgressBar from 'primevue/progressbar';
import Button from 'primevue/button';
import BadgeDirective from 'primevue/badgedirective';
import Badge from 'primevue/badge';
import AWS from 'aws-sdk';
import Compressor from 'compressorjs';
import Image from 'primevue/image';
import Toast from 'primevue/toast';
import { uploadMultipleImageBackend } from "@/api";

export default {
    components: {
        FileUpload,
        Button,
        ProgressBar,
        BadgeDirective,
        Badge,
        Compressor,
        Image,
        Toast,
    },
    data() {
        return {
            files: [],
            uploadedFiles: [],
            uploadedFilesCount: 0,
            totalSize: 0,
            totalSizePercent: 0,
            randomNumber: 0,
            loading: false,
        };
    },
    methods: {
        onRemoveTemplatingFile(file, removeFileCallback, index) {
            removeFileCallback(index);
            this.totalSize -= parseInt(this.formatSize(file.size));
            this.totalSizePercent = this.totalSize / 10;
        },
        onClearTemplatingUpload(clear) {
            clear();
            this.totalSize = 0;
            this.totalSizePercent = 0;
        },
        onSelectedFiles(event) {
            this.$refs.toast.add({ severity: 'info', summary: 'Success', detail: 'Click on upload icon', life: 3000 });
            if (event.files.length > 5) {
                this.$emit('limit-exceeded', true);
                return;
            }
            this.files = event.files;
            this.files.forEach((file) => {
                this.totalSize += parseInt(this.formatSize(file.size));
            });
        },
        async uploadEvent(callback) {
            try {
                this.loading = true;

                const formData = new FormData();
                this.files.forEach(file => formData.append('files[]', file));

                // Call Laravel API to upload files
                const response = await uploadMultipleImageBackend(formData);

                // Handle response
                this.uploadedFiles = response.data.uploadedFiles;
                console.log(this.uploadedFiles);
                
                this.uploadedFilesCount = this.uploadedFiles.length;
                this.loading = false;

                this.$emit('uploaded-files-updated', this.uploadedFiles);
            } catch (error) {
                this.loading = false;
                console.error('Error uploading images:', error);
                this.$refs.toast.add({ severity: 'error', summary: 'Error', detail: 'Failed to upload images', life: 3000 });
            }

            this.totalSizePercent = this.totalSize / 10;
            callback();
        },
         generateRandomNumber() {
            const min = 100000; 
            const max = 999999; 
            return Math.floor(Math.random() * (max - min + 1)) + min;
        },
        async compressImage(file) {
            var character = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
            if (character.test(this.name) || character.test(this.lastname)) {
                this.feedback = 'Special character present, please correct'
            }
            return new Promise((resolve, reject) => {
                const compressor = new Compressor(file, {
                    quality: 0.6,
                    maxWidth: 400,
                    maxHeight: 400,
                    success(result) {
                        resolve(result);
                    },
                    error(err) {
                        reject(err);
                    },
                });

            });
        },
        async deleteAllUploadedFiles() {
            if (this.uploadedFiles.length > 0) {
                for (const file of this.uploadedFiles) {
                    const s3 = new AWS.S3();
                    const bucketName = 'volunteers-pk-live';

                    const params = {
                        Bucket: bucketName,
                        Key: file.image,
                    };
                    const compressedParams = {
                        Bucket: bucketName,
                        Key: file.image_thumb,
                    };
                    try {
                        await s3.deleteObject(params).promise();
                        // console.log(`Image '${file.image}' deleted successfully.`);
                        await s3.deleteObject(compressedParams).promise();
                        // console.log(`CompressdImage '${file.image_thumb}' deleted successfully.`);
                    } catch (error) {
                        console.error(`Error deleting image '${imageKey}':`, error);
                    }
                }
            }
        },
        async deleteImageFromS3(imageKey) {
            var arrR = this.uploadedFiles.filter(function (ele) {
                return (ele.image == 'milkar/images/' + imageKey.name)
            });
            const s3 = new AWS.S3();
            const bucketName = 'volunteers-pk-live';

            const params = {
                Bucket: bucketName,
                Key: 'milkar/images/' + imageKey.name,
            };
            const compressedParams = {
                Bucket: bucketName,
                Key: arrR[0].image_thumb,
            };

            try {
                await s3.deleteObject(params).promise();
                // console.log(`Image '${imageKey}' deleted successfully.`);
                await s3.deleteObject(compressedParams).promise();
                // console.log(`CompressdImage '${arrR[0].image_thumb}' deleted successfully.`);

                const condition = (ele) => ele.image === 'milkar/images/' + imageKey.name;

                // Find the index of the first element that meets the condition
                const indexToRemove = this.uploadedFiles.findIndex(condition);

                // If an element matching the condition is found, remove it from the array
                if (indexToRemove !== -1) {
                    this.uploadedFiles.splice(indexToRemove, 1);
                }
                this.$emit('uploaded-files-updated', this.uploadedFiles);
            } catch (error) {
                console.error(`Error deleting image '${imageKey}':`, error);
            }
        },
        onTemplatedUpload() {
            this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
        },
        formatSize(bytes) {
            const k = 1024;
            const dm = 3;
            const sizes = this.$primevue.config.locale.fileSizeTypes;

            if (bytes === 0) {
                return `0 ${sizes[0]}`;
            }

            const i = Math.floor(Math.log(bytes) / Math.log(k));
            const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

            return `${formattedSize} ${sizes[i]}`;
        }
    }
};
</script>